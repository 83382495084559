@use '../../sass/main';

.create-tasks-modal {
    height: 100%;
    @include main.flex(space-between, center, column);


    .form-tabs {
        @include main.flex();
        width: 100%;

        button {
            padding: 10px 20px;
            cursor: pointer;
            border-radius: 5px;
            font-size: 16px;
            font-weight: 500;
            color: blue;
            transition: all 0.3s;
            min-width: 60px;
            background: white;

            &.active {
                background-color: blue;
                color: white;
            }

            &:hover {
                background-color: main.$gray-200;
            }
        }

    }

    .form-and-preview {
        @include main.flex($justify: space-between, $direction: row);
        width: 90%;
        height: fit-content;
        box-sizing: border-box;
        
        .form-preview {
            max-width: 45%;
            min-width: 30%;
        }

        .form-content {
            @include main.flex($justify: flex-start, $align: flex-start, $direction: column);
            padding: 20px;
            width: 50%;
    
            .area-form {
                width: 100%;

                .task-input-group {
                    @include main.flex($direction: row);
                    width: 100%;
            
                    .area-input {
                        @include main.flex();
                        text-align: center;
                        border: 1px solid main.$gray-400;
                        cursor: pointer;
                        width: 100px;
                        height: 100px;
                        margin: 30px;
                        border-radius: 4px;
                    
                        &.selected {
                            background: blue;
                            color: white;
                        }
                    
                    }
                }
            }
    
            .goal-form {
                width: 100%;

                .task-input-group {
                    @include main.flex($direction: column);
                    width: 100%;
            
                    .goal-input {
                        @include main.flex();
                        text-align: center;
                        border: 1px solid main.$gray-400;
                        cursor: pointer;
                        width: 100%;
                        height: 30px;
                        border-radius: 4px;
                    
                        &.selected {
                            background: blue;
                            color: white;
                        }
                    
                    }
                }
            }
    
            .deliverable-form {
                width: 100%;
    
                .task-input-group {
                    @include main.flex($align: flex-start, $direction: column);
                    width: 100%;
            
                    .option-checkbox {
                        @include main.flex($justify: flex-start, $direction: row);
                        width: 100%;
                    }

                    .addon {
                        width: 100%;
                    }
                }          
            }
            
            .date-form {
                width: 100%;

                .date-picker {
                    width: 100%;
                    height: 30px;
                }
            }
            
            .details-form {
                width: 100%;
            
                .details-element-select {
                    width: 100%;
                    border: 1px solid main.$gray-400;
                    cursor: pointer;
                    padding: 10px;

                    &.selected {
                        background: main.$secondary-color;
                        color: white;
                    }
                }
            
            }

            .tags-form {
                width: 100%;

                .tag-item {
                    @include main.flex($align: center,$justify: flex-start, $direction: row);

                    .remove-tag-icon {
                        width: 20px;
                        height: 20px;
                        cursor: pointer;
                    }    
                }

                .new-tag-input {
                    @include main.flex($align: center,$justify: flex-start, $direction: row);
                    width: 50%;   
                }
            }
        }

    }

    .form-footer {
        @include main.flex($direction: column);
        width: 100%;

        .validation-error {
            text-align: center;
            width: 100%;
            color: red;
            font-size: 14px;
        }

        .form-buttons {
            @include main.flex(space-between, center);
            width: 100%;
            padding-left: 60px;
            padding-right: 60px;

            &.flex-end {
                justify-content: flex-end;
            }
        }
    }

}

.selected-items {
    width: 100%;
    @include main.flex($justify: space-between, $align: flex-start, $direction: row);
    flex-wrap: wrap;
}


