@use '../../sass/main';

.user-menu {
  position: absolute;
  top: 15px;
  right: 30px;
  z-index: 1000;
}

.user-dropdown-menu {
  position: absolute;
  border-radius: 6px;
  box-shadow: 0px 5px 15px 0px rgba(0, 0, 0, 0.20);
  width: 200px;
  z-index: 1001;
  background: main.$white;
  padding-left: 10px;
  right: 15px;

  ul {
      list-style-type: none;
      padding: 0px;
      
      li {
          width: 100%;
          padding: 5px;
          box-sizing: border-box;

          &:hover{
              cursor: pointer;
              background: main.$gray-300;
          }
      }
  }
}

.user-form {
  text-align: center;
}