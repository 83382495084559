@use '../../sass/main';

.brand-guide {
  @include main.flex($justify: space-between, $align: flex-start, $direction: row);

  .left-area {
    @include main.flex($direction: column);
    width: 20%;

    button {
      width: 100%;
      background: rgb(216, 29, 29);
      font-size: 16px;
    }
  }
  .tabs {
    @include main.flex($direction: column, $justify: flex-start, $align: flex-start);
    width: 100%;
    border: 1px solid main.$gray-400;

    .tab {
      @include main.flex($direction: row, $justify: flex-start, $align: center);
      width: 100%;
      padding: 10px;
      cursor: pointer;
      box-sizing: border-box;
      border: 1px solid main.$gray-300;
      background: white;

      &.active {
        background-color: blue;
        color: white;
      }
    }
  }

  .tab-content {
    width: 75%;
    border: 1px solid main.$gray-400;
    padding: 20px;
    background: white;

    .brand-form{
      text-align: center;
    }
  }

  .new-form {
    @include main.flex();
    width: 100%;
  }

  .item-view {
    text-align: left;

    label {
      font-weight: bold;
    }
  }

  .form-buttons {
    @include main.flex();
    width: 100%;

  }

  .editing-row {
    background: main.$gray-300;
  }

}