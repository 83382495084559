@use '../../sass/main';

.item-view {
    background: #f0a7a0;
    border: 1px solid main.$gray-400;
    border-radius: 5px;
    padding: 10px;
    color: black;
    font-size: small;
    width: 45%;

    h3 {
        width: 100%;
        text-align: center;
    }

    label {
        font-weight: bold;
    }   
    
    &.position-absolute {
        position: fixed;
        max-width: 20vw;
        top: 40%;
        right: 40%;
    }
}

