@use '../../sass/main';


.dropdown-overlay {
    position: fixed;
    z-index: 1000;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
}

.dropdown-menu {
    position: absolute;
    border-radius: 6px;
    box-shadow: 0px 5px 15px 0px rgba(0, 0, 0, 0.20);
    width: 200px;
    z-index: 1001;
    background: main.$white;
    padding-left: 10px;

    .close-action {
        position: absolute;
        top: -10px;
        right: -10px;
        width: 20px;
        height: 20px;
        border: 1px solid main.$gray-300;
        border-radius: 50%;
        padding: 3px;
        background: main.$white;

        &:hover {
            background: main.$gray-200;
        } 
    }


    ul {
        list-style-type: none;
        padding: 0px;
        
        li {
            width: 100%;
            padding: 5px;
            box-sizing: border-box;

            &:hover{
                cursor: pointer;
                background: main.$gray-300;
            }
        }
    }
}