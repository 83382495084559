@use '../../sass/main';

.page-title {

    h1 {
      color: #565656;
      font-size: 36px;
      font-style: normal;
      font-weight: 600;
      line-height: 44px;
      margin: 0;
    }

    hr {
      display: inline-block;  
      margin-bottom: 15px;
      margin-top: 15px;
      border: none;
      border-top: 1px solid #000; 
      width: 124px;
      height: 1px;
    }

    p {
      color: #ABABAB;
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: 28px;
      margin: 0; 
    }

}