@use '../../sass/main';


.users-invite-list {
    position: absolute;
    width: 95%;
    box-sizing: border-box;
    background: main.$white;
    border-radius: 8px;
    border: 1px solid main.$gray-100;
    box-shadow: 0px 12px 16px -4px main.$gray-300;
    max-height: 50%;
    overflow-y: auto;
    padding: 5px;

    .invite-new-user {
        padding: 15px;;

        &:hover {
            background-color: blue;
            color: white;
            font-weight: bold;
            cursor: pointer;
        }

    }

    .user-info {
        @include main.flex(flex-start, center);

        &:hover {
            background-color: main.$gray-100;
            cursor: pointer;
        }
    }
}

.client-users-list {
    border-radius: 8px;
    border: 1px solid main.$gray-100;
    box-shadow: 0px 12px 16px -4px main.$gray-100;
    height: 50vh;
    overflow-y: auto;
    padding: 5px;

    .client-user-info {
        @include main.flex(space-between, center);

    
        .user-details {
            @include main.flex();
        }
        .user-actions {
            @include main.flex();

            .action-icon {
                cursor: pointer;
                width: 25px;
                height: 25px;
            }
            
        }

    }
}