@use '../../sass/main';

.rate-agent-component {


  .page-title {
    text-align: center;

    h1 {
      font-size: 22px;
    }

  }

  .rate-section {
    .rate-section-title {
      text-align: center;
    
      h2 {
        font-size: 16px;
        font-style: italic;
      }
    }
  }

  .footer {
    @include main.flex();
    width: 100%;

    .thanks-message {
      @include main.flex($justify: center, $align: center);
      background: green;
      color: white;
      padding: 15px;
      border-radius: 5px;
      font-size: 14px;
    }

  }

  .error {
    color: red;
    font-size: 12px;
    text-align: right;
    width: 90%;
    margin-top: 3px;

    &.center {
      text-align: center;
      margin-top: 6px;
    }
  }

}

.topic-rating {
  @include main.flex($justify: flex-end);
  width: 90%;

  .topic-rating-label {
    text-align: right;

    label {
      font-size: 13px;
      color: main.$gray-900;
   
    }
  
  }
  .topic-rating-content {
    @include main.flex();
  }

  .na-icon {
    border: 1px solid main.$gray-400;
    cursor: pointer;
    padding: 3px;
    font-size: 12px;

    &.selected {
      background: black;
      color: white;
    }
  }
}

.five-star-rating {
  @include main.flex($justify: space-between, $align: flex-start);
  width: 90%;

  .star {
    @include main.flex($direction: column);
    width: 15%;
    text-align: center;

    label {
      font-size: 12px;
      color: main.$gray-900;
    }
  }

}