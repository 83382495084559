@use '../../sass/main';

.task-editor {
  background: main.$gray-100;
  box-sizing: border-box;
  height: 100%;
}

.ai-icon-container {
  border: 1px solid main.$gray-300;
  border-radius: 5px;
  background: white;
  padding: 2px;
}

.ai-actions-menu {
  background: white;
  border: 1px solid main.$gray-300;
  border-radius: 5px;
  min-width: 200px;

  &.large {
    width: 50%;
  }

  .generated-text {
    padding: 10px;
    border-bottom: 1px solid main.$gray-300;
    background: main.$gray-200;
  }

  .loading-container {
    @include main.flex(center);
    padding: 10px;
    width: 100%;
  }
  
  .ai-actions-menu-item {
    cursor: pointer;
    padding: 10px;
    border: 1px solid main.$gray-300;
    border-radius: 5px;
    box-sizing: border-box;

    &:hover {
      background: main.$gray-200;
    }
  }

  .ai-actions-menu-custom {
    @include main.flex(space-between);
    padding-right: 10px;

    .send-action-icon {
      color: main.$primary-color;
      width: 20px;
      height: 20px;
      cursor: pointer;
    }

  }

}

.confirm-action-container {
  background: main.$gray-300;
  border: 1px solid main.$gray-300;
  border-radius: 5px;
  min-width: 150px;
  padding: 15px;

  .confirm-action-text {
    width: 100%;
    text-align: center;
  }

  .confirm-action-buttons {
    @include main.flex();
    width: 100%;
  }
}

