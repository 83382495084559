@use './main';

table {
    margin-top: 20px;
    border-collapse: collapse;
    width: 100%;
    background: white;
    text-align: left;

    thead {
        @include main.flex(flex-start);
        height: 44px;
        padding: 12px 0px;
        gap: 12px;
        align-self: stretch;
        border-bottom: 1px solid main.$gray-200;
        background: main.$gray-200;
        color: main.$gray-500;
    
        th {
            @include main.flex(flex-start);
            height: 72px;
        
            .sort-component {
                display: none;

                &.active {
                    display: flex;
                }
            }

            &:hover {                           
                .sort-component {
                    display: flex;
                }
            }
        }
    
    }

    tbody {
        display: block;
        overflow-y: auto;
        overflow: visible;

        tr {
            cursor: pointer;
            height: 72px;
            overflow-y: hidden;

            &:hover {
                background: main.$gray-100;
            }
        }

        td {
            @include main.flex(flex-start);


            &.truncate {
                overflow: hidden;
                display: -webkit-box;
                -webkit-line-clamp: 1; 
                -webkit-box-orient: vertical;
            }

            .user-avatar-container {
                @include main.flex(flex-start);
                overflow: hidden;


            }

            .crud-item-action {
                width: 30px;
                color: main.$gray-400;
                cursor: pointer;
            }

            &.align-center {
                justify-content: center;
            }

            &.align-right {
                justify-content: flex-end;
            }

            .logo {
                max-height: 50px;
            }
        }
    }

    tr {
        @include main.flex(flex-start);
        gap: 12px;
        height: 44px;
        padding: 12px 24px;
        border-bottom: 1px solid main.$gray-200;
        width: 100%;
        box-sizing: border-box; 
        
    }


    &.primary {
        thead {
            background: main.$primary-color;
            color: main.$white;
            height: 20px;
        }

        tbody {
            tr {
                height: 50px;
            }
        }
    }

}