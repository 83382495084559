// Cores

$gray-100: #F9F5FF;
$gray-200: #EAECF0;
$gray-300: #D0D5DD;
$gray-400: #98A2B3;
$gray-500: #667085;
$gray-900: #101828;

$brand-600: #7F56D9;
$brand-700: #6941C6;

$error-600: #D92D20;
$white: #fff;

$primary-color: #05299e;
$secondary-color: #5e4ae3;
$error-color: #f00;
$text-color: #333;
$background-color: $gray-200;
$navbar-color: #1c4d93;

// Fontes
$font-stack: Helvetica, sans-serif;
$font-fallback: Arial, sans-serif;
$font-size-smallest: 12px;
$font-size-small: 15px;
$font-size-medium: 18px;
$font-size-large: 20px;
