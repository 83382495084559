@use '../../sass/main';


.order-icon {
    width: 20px;
    height: 20px;
    margin-left: 5px;
    cursor: pointer;
    display: block;
}
 