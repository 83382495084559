.margin-top-small {
  margin-top: 10px;
}

.margin-bottom-small {
  margin-bottom: 10px;
}

.margin-left-small {
  margin-left: 10px;
}

.margin-right-small {
  margin-right: 10px;
}

.margin-top-medium {
  margin-top: 30px;
}

.margin-bottom-medium {
  margin-bottom: 30px;
}

.margin-left-medium {
  margin-left: 30px;
}

.margin-right-medium {
  margin-right: 30px;
}

.margin-top-large {
  margin-top: 50px;
}

.margin-bottom-large {
  margin-bottom: 50px;
}

.margin-left-large {
  margin-left: 50px;
}

.margin-right-large {
  margin-right: 50px;
}

.margin-top-extra-small {
  margin-top: 5px;
}

.margin-bottom-extra-small {
  margin-bottom: 5px;
}

.margin-left-extra-small {
  margin-left: 5px;
}

.margin-right-extra-small {
  margin-right: 5px;
}

.margin-top-extra-large {
  margin-top: 100px;
}

.margin-bottom-extra-large {
  margin-bottom: 100px;
}

.margin-left-extra-large {
  margin-left: 100px;
}

.margin-right-extra-large {
  margin-right: 100px;
}

table {
  margin-top: 20px;
  border-collapse: collapse;
  width: 100%;
  background: white;
  text-align: left;
}
table thead {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
  flex-wrap: nowrap;
  height: 44px;
  padding: 12px 0px;
  gap: 12px;
  align-self: stretch;
  border-bottom: 1px solid #EAECF0;
  background: #EAECF0;
  color: #667085;
}
table thead th {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
  flex-wrap: nowrap;
  height: 72px;
}
table thead th .sort-component {
  display: none;
}
table thead th .sort-component.active {
  display: flex;
}
table thead th:hover .sort-component {
  display: flex;
}
table tbody {
  display: block;
  overflow-y: auto;
  overflow: visible;
}
table tbody tr {
  cursor: pointer;
  height: 72px;
  overflow-y: hidden;
}
table tbody tr:hover {
  background: #F9F5FF;
}
table tbody td {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
  flex-wrap: nowrap;
}
table tbody td.truncate {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}
table tbody td .user-avatar-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
  flex-wrap: nowrap;
  overflow: hidden;
}
table tbody td .crud-item-action {
  width: 30px;
  color: #98A2B3;
  cursor: pointer;
}
table tbody td.align-center {
  justify-content: center;
}
table tbody td.align-right {
  justify-content: flex-end;
}
table tbody td .logo {
  max-height: 50px;
}
table tr {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
  flex-wrap: nowrap;
  gap: 12px;
  height: 44px;
  padding: 12px 24px;
  border-bottom: 1px solid #EAECF0;
  width: 100%;
  box-sizing: border-box;
}
table.primary thead {
  background: #05299e;
  color: #fff;
  height: 20px;
}
table.primary tbody tr {
  height: 50px;
}

.icon {
  width: 20px;
  height: 20px;
  cursor: pointer;
}

.icon-small {
  width: 20px;
  cursor: pointer;
}

.icon-medium {
  width: 30px;
  cursor: pointer;
}

.icon-large {
  width: 40px;
  cursor: pointer;
}

.text-ai-icon {
  width: 25px;
  height: 25px;
  color: blue;
  cursor: pointer;
}

.ai-generate-icon {
  width: 25px;
  height: 25px;
  color: rgb(158, 158, 28);
  cursor: pointer;
}

html {
  zoom: 100%;
}

html, body, #root {
  margin: 0;
  height: 100%;
  overflow: hidden;
}

body {
  font-family: "Poppins", Arial, sans-serif;
  color: #333;
  background-color: #EAECF0;
}

.app {
  height: 100%;
  display: flex;
  justify-content: flext-start;
  align-items: flex-start;
  flex-direction: row;
  flex-wrap: nowrap;
}

.page {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding: 50px;
  overflow: scroll;
}
.page.center-content {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  flex-wrap: nowrap;
}

.column-1 {
  width: calc(8.3333333333% * 1);
}

.column-2 {
  width: calc(8.3333333333% * 2);
}

.column-3 {
  width: calc(8.3333333333% * 3);
}

.column-4 {
  width: calc(8.3333333333% * 4);
}

.column-5 {
  width: calc(8.3333333333% * 5);
}

.column-6 {
  width: calc(8.3333333333% * 6);
}

.column-7 {
  width: calc(8.3333333333% * 7);
}

.column-8 {
  width: calc(8.3333333333% * 8);
}

.column-9 {
  width: calc(8.3333333333% * 9);
}

.column-10 {
  width: calc(8.3333333333% * 10);
}

.column-11 {
  width: calc(8.3333333333% * 11);
}

.column-12 {
  width: calc(8.3333333333% * 12);
}

.width-1 {
  width: 1%;
}

.width-2 {
  width: 2%;
}

.width-3 {
  width: 3%;
}

.width-4 {
  width: 4%;
}

.width-5 {
  width: 5%;
}

.width-6 {
  width: 6%;
}

.width-7 {
  width: 7%;
}

.width-8 {
  width: 8%;
}

.width-9 {
  width: 9%;
}

.width-10 {
  width: 10%;
}

.width-11 {
  width: 11%;
}

.width-12 {
  width: 12%;
}

.width-13 {
  width: 13%;
}

.width-14 {
  width: 14%;
}

.width-15 {
  width: 15%;
}

.width-16 {
  width: 16%;
}

.width-17 {
  width: 17%;
}

.width-18 {
  width: 18%;
}

.width-19 {
  width: 19%;
}

.width-20 {
  width: 20%;
}

.width-21 {
  width: 21%;
}

.width-22 {
  width: 22%;
}

.width-23 {
  width: 23%;
}

.width-24 {
  width: 24%;
}

.width-25 {
  width: 25%;
}

.width-26 {
  width: 26%;
}

.width-27 {
  width: 27%;
}

.width-28 {
  width: 28%;
}

.width-29 {
  width: 29%;
}

.width-30 {
  width: 30%;
}

.width-31 {
  width: 31%;
}

.width-32 {
  width: 32%;
}

.width-33 {
  width: 33%;
}

.width-34 {
  width: 34%;
}

.width-35 {
  width: 35%;
}

.width-36 {
  width: 36%;
}

.width-37 {
  width: 37%;
}

.width-38 {
  width: 38%;
}

.width-39 {
  width: 39%;
}

.width-40 {
  width: 40%;
}

.width-41 {
  width: 41%;
}

.width-42 {
  width: 42%;
}

.width-43 {
  width: 43%;
}

.width-44 {
  width: 44%;
}

.width-45 {
  width: 45%;
}

.width-46 {
  width: 46%;
}

.width-47 {
  width: 47%;
}

.width-48 {
  width: 48%;
}

.width-49 {
  width: 49%;
}

.width-50 {
  width: 50%;
}

.width-51 {
  width: 51%;
}

.width-52 {
  width: 52%;
}

.width-53 {
  width: 53%;
}

.width-54 {
  width: 54%;
}

.width-55 {
  width: 55%;
}

.width-56 {
  width: 56%;
}

.width-57 {
  width: 57%;
}

.width-58 {
  width: 58%;
}

.width-59 {
  width: 59%;
}

.width-60 {
  width: 60%;
}

.width-61 {
  width: 61%;
}

.width-62 {
  width: 62%;
}

.width-63 {
  width: 63%;
}

.width-64 {
  width: 64%;
}

.width-65 {
  width: 65%;
}

.width-66 {
  width: 66%;
}

.width-67 {
  width: 67%;
}

.width-68 {
  width: 68%;
}

.width-69 {
  width: 69%;
}

.width-70 {
  width: 70%;
}

.width-71 {
  width: 71%;
}

.width-72 {
  width: 72%;
}

.width-73 {
  width: 73%;
}

.width-74 {
  width: 74%;
}

.width-75 {
  width: 75%;
}

.width-76 {
  width: 76%;
}

.width-77 {
  width: 77%;
}

.width-78 {
  width: 78%;
}

.width-79 {
  width: 79%;
}

.width-80 {
  width: 80%;
}

.width-81 {
  width: 81%;
}

.width-82 {
  width: 82%;
}

.width-83 {
  width: 83%;
}

.width-84 {
  width: 84%;
}

.width-85 {
  width: 85%;
}

.width-86 {
  width: 86%;
}

.width-87 {
  width: 87%;
}

.width-88 {
  width: 88%;
}

.width-89 {
  width: 89%;
}

.width-90 {
  width: 90%;
}

.width-91 {
  width: 91%;
}

.width-92 {
  width: 92%;
}

.width-93 {
  width: 93%;
}

.width-94 {
  width: 94%;
}

.width-95 {
  width: 95%;
}

.width-96 {
  width: 96%;
}

.width-97 {
  width: 97%;
}

.width-98 {
  width: 98%;
}

.width-99 {
  width: 99%;
}

.width-100 {
  width: 100%;
}

h1 {
  color: #101828;
  font-size: 25px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
  margin: 0;
}

h2 {
  color: #101828;
  font-size: 25px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  margin: 0;
}

a {
  color: rgb(45, 45, 240);
  text-decoration: none;
  cursor: pointer;
  font-weight: bold;
}

.font-size-smallest {
  font-size: 12px;
}

.font-size-small {
  font-size: 15px;
}

.font-size-medium {
  font-size: 18px;
}

.font-size-large {
  font-size: 20px;
}

.half-width {
  width: 50%;
}

