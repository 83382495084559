@use '../../sass/main';


.back-button {
    @include main.flex(center, center);
    position: absolute;
    top: 10px;
    left: 10px;
    height: 50px;
    border-radius: 5px;
    background-color: rgba(240, 175, 224, 0.822);
    z-index: 100000;
    
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    padding: 0 15px 0 15px;
    cursor: pointer;
}

.task-work-area {
    width: 100%;

    .task-timeline-container {
        @include main.flex(center, flex-start);
        width: 100%;
        background: white;
        padding: 20px 0 10px 0; 
        position: relative;

        .timeline-history-icon {
            position: absolute;
            top: 10px;
            right: 10px;
            cursor: pointer;
            width: 30px;
            height: 30px;
            color: main.$primary-color;
        }
        
    }

    .icons-container {
        @include main.flex(center, center);

        .icon-box {
            @include main.flex(center, center);
            border: 1px solid rgba(0, 0, 0, 0.192);
            padding: 5px;
            border-radius: 5px;
            cursor: pointer;
            font-size: 14px;

            &.selected {
                background: main.$secondary-color;
                color: white;
            }
        }
      }

    .central-container {
        @include main.flex(space-between, flex-start);
        width: 100%;
        height: 70vh;

        .task-fields-container {
            @include main.flex($justify: space-between,$direction: column);
            width: 25%;
            height: 100%;
            border: 1px solid rgba(0, 0, 0, 0.1);
            background: white;

            .task-field-container {
                @include main.flex(flex-start, center, column);
                width: 100%;
                height: calc(100% / 3);
                border-bottom: 1px solid rgba(0, 0, 0, 0.1);
                cursor: pointer;
                padding: 18px 18px 5px 18px;
                box-sizing: border-box;
                text-align: justify;

                label {
                    font-size: 18px;
                    font-weight: 800;
                    cursor: pointer;

                }

                .content-container {
                    @include main.flex();
                    height: 100%;
                    .content {
                        font-size: 14px;
                        font-weight: 400;
                        text-align: center;
                        overflow: hidden;
                        display: -webkit-box;
                        -webkit-line-clamp: 6; 
                        -webkit-box-orient: vertical;
                    }    
                }
                
                .content-actions {
                    width: 100%;
                    text-align: right;

                    .surprise-icon {
                        color: #ff0000a1;

                        &.disabled {
                            color: #f1f1f1;
                            cursor: not-allowed;
                        }
                    }
                }

                &.selected {
                    background: #05299e61;
                }

                &.disabled {
                    background: #f1f1f1;
                    cursor: not-allowed;
                }
            }


        }

        .task-editor-container {
            @include main.flex();
            width: 50%;
            height: 100%;
            box-sizing: border-box;
            overflow-y: visible;
            position: relative;

            .editor-container {
                width: 100%;
                height: 100%;
                background: white;
                box-sizing: border-box;
                
                .editor {
                    height: 93%;
                    box-sizing: content-box;
                    overflow: hidden;
                }

                button {
                    z-index: 100000;
                }

                &.text-only {
                    padding: 20px;
                }   
            }

            .agent-suggestion-editor {
                height: 40vh;
            }

            .agent-modal-actions {
                @include main.flex();
                width: 100%;

                button {
                    width: 50%;
                }
            }
            
        }

        .task-preview-container {
            width: 25%;
            height: 100%;
            border: 1px solid rgba(0, 0, 0, 0.1);
            background: white;
            padding: 20px;
            box-sizing: border-box;
            overflow-y: auto;
        }

    }
}



.timeline {
    @include main.flex(space-between, flex-start);
    width: 50%;

    .timeline-item {
        @include main.flex(flex-start, center, column);
        text-align: center;
        max-width: 23%;

        .timeline-dot {
            width: 20px;
            height: 20px;
            border-radius: 50%;
            background-color: gray;
            margin-right: 10px;
            margin-bottom: 4px;

            &.active {
                background-color: yellow;
            }    
        }

        .timeline-check-icon {
            color: green;
            width: 23px;
            height: 23px;
            margin-bottom: 4px;
        }
        
        .actions {
            @include main.flex(space-between, center);
        }

        .responsible {
            @include main.flex(center, center);
            font-size: 10px;

            .icon {
                color: main.$primary-color;
                margin-left: 3px;
            }

        }
    }

}

.task-history-container {
    @include main.flex(center, center, column);
    width: 100%;
    height: 100%;
    padding: 20px;
    box-sizing: border-box;

    .history-item {
        @include main.flex(flex-start);
        width: 100%;
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        padding: 20px 0 20px 0;
        box-sizing: border-box;
    }
}
  
