@use '../../sass/main';

.button {
    display: flex;
    padding: 10px 16px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 8px;
    border: 1px solid main.$gray-300; 
    background: black;
    color: main.$white;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    cursor: pointer;

    &.button-primary {
        background: main.$primary-color;
    }

    &.button-secondary {
        background: main.$white;
        color: main.$gray-900;

        &:hover {
            background: main.$primary-color;
            color: white;
        }
    }

    &.button-danger {
        background: main.$error-600;
    }

    &.button-select-info {
        padding: 5px 10px;
        background: none;

        &:hover {
            background: main.$gray-300;
            color: black;
        }
    }

    &.large {
        padding: 15px 30px;
        font-size: 16px;
        width: 100%;
    }

    &.small {
        padding: 5px 10px;
    }

    &.full-width {
        width: 100%;
    }

    &:disabled {
        background: main.$gray-300;
        color: main.$gray-500;
        cursor: not-allowed;
    }
}