@use '../../sass/main';


.modal-overlay {
    position: fixed;
    z-index: 1000;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;

    .close-button {
        position: absolute;
        top: 20px;
        right: 20px;
        background: none;
        border: none;
        font-size: 1.5em;
        cursor: pointer;
        width: 30px;
        color: main.$gray-500;
      }

    .modal-header {
        @include main.flex(center, center, column);
        width: 100%;
        margin-bottom: 20px;

        .modal-icon {
            width: 50px;
            height: 50px;
        }
    }

    .modal-content {
        position: relative;
        background-color: white;
        padding: 20px;
        border-radius: 8px;
        width: 50%;
        overflow-x: hidden;
        overflow-y: auto;
        max-height: 90%;

        &.primary-color {
            background: #2f50bd;
            color: white;
            border: 1px solid rgba(255, 255, 255, 0.52);
            box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.75);
        }

        &.size-small {
            width: 50%;
        }

        &.size-medium {
            width: 50%;
        }

        &.size-large {
            width: 70%;
        }

        &.size-xlarge {
            width: 90%;
        }

        &.size-fit-content {
            width: fit-content;
        }

    }
}