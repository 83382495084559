@use '../../sass/main';

.pagination-component {
    @include main.flex(space-between);
    width: 100%;
    box-sizing: border-box; 
    padding: 20px;;

    button {
        @include main.flex(space-between);
        padding: 10px 16px;
        background: main.$gray-100;
        border: 1px solid main.$gray-200;
        border-radius: 8px; 
        color: main.$gray-900;
        cursor: pointer;

        .switch-page-icon {
            width: 16px;

            &.left {
                margin-right: 5px;
            }

            &.right {
                margin-left: 5px;
            }

        }
    }

    .page-selector {
        padding: 10px;
        border-radius: 8px;
        cursor: pointer;

        &.active {
            background: main.$gray-200;
        }    
    }


}