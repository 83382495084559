@use '../../sass/main';

.navbar {
  background-color: main.$navbar-color;;
  padding: 30px;
  width: 290px;
  height: 100%;
  @include main.flex(flex-start, center, column);
  color: white;
  
  .agency-logo {
    width: 80%;
    background: white;
    padding: 20px;
    border-radius: 8px;
  }

  .agency-name {
    color: white;
  }

  .client-logo {
    max-width: 80%;
    background: white;
    padding: 20px;
    border-radius: 8px;
  }

  .client-header {
    width: 100%;
    text-align: center;
    
    h2 {
      color: white;
    }
  }

  nav {
    @include main.flex(flex-start, flex-start);
    width: 100%;

    ul {
      list-style-type: none;
      padding: 0;

      li {
        margin-bottom: 30px;
      }

      a {
        text-decoration: none;
        color: white;
      }

      a:hover {
        text-decoration: underline;
      }
    }

    .nav-option {
      @include main.flex(flex-start, center);
      width: 100%;

      .nav-option-icon {
        width: 30px;
        margin-right: 10px;
      }
    }
  }


  .back-to-agency {
    @include main.flex(flex-start, flex-start, column);
    width: 100%;

    .action {
      @include main.flex(flex-start, center);
      cursor: pointer;

      .back-to-agency-icon {
        width: 20px;
        margin-right: 10px;
      }
      span {
        color: white;
      }
    }
    
    hr { 
      margin-bottom: 30px;
      margin-top: 30px;
      border: none;
      border-top: 1px solid main.$gray-200; 
      width: 100%;
      height: 1px;
    }
  }
}