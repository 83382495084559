// Distancias
$margin-small: 10px;
$margin-medium: 30px;
$margin-large: 50px;
$margin-extra-small: 5px;
$margin-extra-large: 100px;

.margin-top-small {
  margin-top: $margin-small;
}

.margin-bottom-small {
  margin-bottom: $margin-small;
}

.margin-left-small {
  margin-left: $margin-small;
}

.margin-right-small {
  margin-right: $margin-small;
}

.margin-top-medium {
  margin-top: $margin-medium;
}

.margin-bottom-medium {
  margin-bottom: $margin-medium;
}

.margin-left-medium {
  margin-left: $margin-medium;
}

.margin-right-medium {
  margin-right: $margin-medium;
}

.margin-top-large {
  margin-top: $margin-large;
}

.margin-bottom-large {
  margin-bottom: $margin-large;
}

.margin-left-large {
  margin-left: $margin-large;
}

.margin-right-large {
  margin-right: $margin-large;
}

.margin-top-extra-small {
    margin-top: $margin-extra-small;
}
.margin-bottom-extra-small {
    margin-bottom: $margin-extra-small;
}
.margin-left-extra-small {
    margin-left: $margin-extra-small;
}
.margin-right-extra-small {
    margin-right: $margin-extra-small;
}

.margin-top-extra-large {
    margin-top: $margin-extra-large;
}
.margin-bottom-extra-large {
    margin-bottom: $margin-extra-large;
}
.margin-left-extra-large {
    margin-left: $margin-extra-large;
}
.margin-right-extra-large {
    margin-right: $margin-extra-large;
}