@use '../../sass/main';

.crud-area {
    @include main.flex(normal, flex-start, column);
    width: 100%;
    min-height: 50%;

    .crud-area-header {
        @include main.flex(flex-end);
        width: 100%;
        margin-bottom: 10px;           
    }

    .crud-card {
        width: 100%;
        height: 100%;
        border-radius: 8px;
        border: 1px solid main.$gray-200;
        background: main.$white;
        box-shadow: 0px 1px 3px 0px rgba(16, 24, 40, 0.10), 0px 1px 2px 0px rgba(16, 24, 40, 0.06);


        .crud-card-header {
            @include main.flex(space-between);
            width: 100%;
            padding: 20px;
            box-sizing: border-box; 

            .left-container {
                @include main.flex();
            }
        }



        .empty-table {
            @include main.flex(center, center);
            width: 100%;
            padding: 30px;
            text-align: center;

            img {
                height: 400px;
            }
        }

    }
}


.crud-form {
    @include main.flex(space-between, center, column);
    height: 100%;

    .form-content {
        @include main.flex(flex-start, center, column);
        width: 100%;      
    }

    .form-content-user {
        @include main.flex(flex-start, flex-start, column);
        width: 100%;
        height: 100%;

        .email-column {
            width: 65%;
        }
    
        .access-column {
            width: 25%;
        }

        .close-column {
            width: 5%;
        }

        .pending-status {
            color: main.$gray-400;
        }

        .form-labels {
            @include main.flex(space-between);
            width: 100%;
            margin-bottom: 10px;
        }

        .input-line {
            @include main.flex(space-between, flex-start);
            width: 100%;
 
        }
    }
    .form-footer {
        @include main.flex(space-between, flex);
        width: 100%;
        margin-top: 20px;
    }   
}

