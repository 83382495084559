@use '../../sass/main';

.agent-conversation-component {
    width: 100%;
    height: 100%;
    box-sizing: border-box;

    .conversation {
        @include main.flex(flex-start, center, column);
        width: 100%;
        box-sizing: border-box;
        height: 100%;
        background: white;
        
        &.extra-padding-top {
            padding-top: 40px;
        }

        .messages-container {
            @include main.flex(flex-start, center, column);
            width: 100%;
            height: 100%;
            overflow: auto; 
            padding: 20px;
            box-sizing: border-box;

            &.conversation-started {
                @include main.flex(flex-end, center, column);
            }
        
            &::-webkit-scrollbar {
                width: 10px;
            }
            
            &::-webkit-scrollbar-track {
                background: #f1f1f1;
            }
        
            &::-webkit-scrollbar-thumb {
                background: main.$gray-300;
                border-radius: 10px;
            }
        
            &::-webkit-scrollbar-thumb:hover {
                background: #555;
            }
        
        }

        .agent-image {
            width: 60px;
            height: 60px;
        }

        .message {
            @include main.flex(flex-start, flex-start, column);
            width: 100%;
        
            &.current-user {
                @include main.flex(flex-end);
                width: 100%;

                .current-user-msg {
                    @include main.flex(flex-end);
                    padding: 5px;
                    background: main.$gray-200;
                    border-radius: 20px;
                    max-width: 80%;
                }
            }
        
            .message-container {
                @include main.flex(flex-start);
            }
        }

        .briefing-cards {
            @include main.flex(center, center, $wrap: wrap);
            width: 100%;
        }

    }

    .error-message {
        @include main.flex(center, center);
        color: red;
        font-size: 14px;
    }

    .conversation-user-input {
        @include main.flex(space-between, center);
        width: 100%;
        height: 10%;
        padding: 20px;

        .input-area {
            width: 90%;
        }

        .start-input {
            width: 100%;
            @include main.flex();
        }
    }        

}
