@import 'variables';
@import 'mixins';
@import 'distances';
@import 'tables';
@import 'icons';

html {
  zoom: 100%;
}

html, body, #root {
  margin: 0;
  height: 100%;
  overflow: hidden;
}

body {
  font-family: 'Poppins', Arial, sans-serif;
  color: $text-color;
  background-color: $background-color;
}

.app {
  height: 100%;
  @include flex(flext-start, flex-start);
}

.page {
  width: 100%;
  height: 100%;
  box-sizing: border-box; 
  padding: 50px;
  overflow: scroll;

  &.center-content {
    @include flex(center, center);
  }
}

@for $i from 1 through 12 {
  .column-#{$i} {
      width: calc((100% / 12) * #{$i});
  }
}

@for $i from 1 through 100 {
  .width-#{$i} {
      width: #{$i*1%};
  }
}


h1 {
  color: $gray-900;
  font-size: 25px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
  margin: 0;
}

h2 {
  color: $gray-900;
  font-size: 25px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  margin: 0;
}


a {
  color: rgb(45, 45, 240);
  text-decoration: none;
  cursor: pointer;
  font-weight: bold;
}
.font-size-smallest {
  font-size: $font-size-smallest;
}

.font-size-small {
  font-size: $font-size-small;
}

.font-size-medium {
  font-size: $font-size-medium;
}

.font-size-large {
  font-size: $font-size-large;
}

.half-width {
  width: 50%;
}