@use '../../sass/main';


.actions-header {
    @include main.flex(flex-end);
    width: 100%;
}

.empty-table {
    @include main.flex(center, center);
    width: 100%;
    text-align: center;

    img {
        height: 200px;
    }
}