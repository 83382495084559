@use '../../sass/main';


.user-avatar {

    img {
        border: 1px solid main.$gray-300;
        background: white;
        border-radius: 50%;
        object-fit: cover;
        cursor: pointer;
    
    
        &.small {
            width: 25px;
            height: 25px;
        }

        &.medium {
            width: 35px;
            height: 35px;
        }

        &.large {
            width: 45px;
            height: 45px;
        }
    }
}