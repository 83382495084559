@use '../../sass/main';

.sandbox-page-title {
  text-align: center;

  h1 {
    color: main.$gray-500;
    font-size: 32px;
  }

}
.sandbox-container {
  @include main.flex($justify: space-between, $align: flex-start);
}

.task-card-container,.evaluation-card-container, .generated-text-container  {
  border: 1px solid main.$gray-400;
  border-radius: 5px;
  box-shadow: 0 0 5px 0 main.$gray-400;
  padding: 20px;
  box-sizing: border-box;
  background: white;
}

.task-card-container {
  width: 30%;
  height: 80vh;
}

.evaluation-card-container {
  width: 30%;
}

.generated-text-container {
  width: 35%;
}
