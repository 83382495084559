@use '../../sass/main';

.info-card {
    @include main.flex($direction: column);
    width: 40%;
    padding: 20px;
    margin: 20px;
    background-color: main.$gray-300;
    border-radius: 12px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    font-size: small;

    &:hover {
        background-color: main.$gray-400;
        cursor: pointer;
    }
  
    .card-header {
      @include main.flex($justify: center);
      width: 100%;
    }
  
    .card-body {
      @include main.flex($direction: column);
      width: 100%;
      text-align: center;
    }

    &.blue-pill {
        background-color: rgb(28, 28, 138);
        color: main.$white;
        width: 80%;
        cursor: auto;
        border-radius: 100px;
    }

    &.red-pill {
        background-color: rgb(228, 25, 25);
        color: main.$white;
        width: 80%;
        cursor: auto;
        border-radius: 100px;
    }
  }
  