@use '../../sass/main';

.card {
    @include main.flex($direction: column);
    width: 30%;
    max-width: 500px;
    padding: 40px;
    background-color: main.$white;
    border-radius: 12px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  
    .card-header {
      @include main.flex($justify: center);
      width: 100%;
    }
  
    .card-body {
      @include main.flex($direction: column);
      width: 100%;
    }
  }
  