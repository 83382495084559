@use '../../sass/main';


.preview-content {
    @include main.flex($justify: flex-start, $direction: column);
    width: 100%;
    box-sizing: border-box;

    &.blue-container{
        background: #05299e;
        color: white;
        border: 1px solid main.$gray-400;
        padding: 20px;
        border-radius: 4px;

        .title {
            color: white;
            font-weight: bolder;
        }
    }

    &.simplified-card {
        background: white;
        border: 1px solid main.$gray-300;
        padding: 5px 10px 30px 10px;
        border-radius: 4px;
        width: 100%;
        text-align: center;

        .title {
            font-weight: bolder;
        }
    }

    .info-columns {
        @include main.flex($justify: flex-start, $direction: column, $align: center);
        width: 100%;
    }

    .info-line {
        @include main.flex($justify: center,  $align: center);
        width: 100%;
    }

    .task-specifications {
        @include main.flex($align: flex-start, $direction: column);
        width: 100%;  
    }

    .info {
        @include main.flex($justify: flex-start, $align: center, $direction: row);
        width: fit-content;
    }

    .inner-info {
        @include main.flex($justify: flex-start, $align: center, $direction: row);
    }

    label {
        font-weight: bolder;
        margin-right: 10px;
    }

}
