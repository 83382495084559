@use '../../sass/main';

.input-group {
  @include main.flex($align: flex-start , $direction: column);
  width: 100%;

  label {
    margin-bottom: 5px;
    font-weight: bold;
  }

  input,select, textarea, .date-picker, option {
    width: 100%;
    padding: 14px;
    border: 1px solid #ccc;
    border-radius: 8px;
    box-sizing: border-box;
    background: main.$white;

    &:disabled {
      color: main.$gray-900;
    }
  
  } 

  input[type="checkbox"] {
    cursor: pointer;
  }

}

.input-validation-error {
  color: red;
  margin-top: 5px;
  font-size: 14px;
}

.upload-area {
  cursor: pointer;
  width: 130px;
  height: 130px;
  border-radius: 50%;
  margin-bottom: 15px;

  &.type-logo {
    background: url(../../assets/logo-upload.png) no-repeat center center; 
    background-size: cover;  
  }

  &.type-user {
    background: url(../../assets/unknown-user.png) no-repeat center center; 
    background-size: cover;  
  }

  &.hide {
    display: none;
  }
}

.photo-preview {
  cursor: pointer;
  max-width: 30%;
  max-height: 200px;
}


input, textarea {
  font-size: 14px;
  font-family: Arial, sans-serif;
}

::placeholder { /* para a maioria dos navegadores */
  font-size: 14px;
  font-family: Arial, sans-serif;
}

::-moz-placeholder { /* para Firefox 19+ */
  font-size: 14px;
  font-family: Arial, sans-serif;
}

:-ms-input-placeholder { /* para IE 10+ */
  font-size: 14px;
  font-family: Arial, sans-serif;
}

::-ms-input-placeholder { /* para Microsoft Edge */
  font-size: 14px;
  font-family: Arial, sans-serif;
}

:-moz-placeholder { /* para Firefox 18- */
  font-size: 14px;
  font-family: Arial, sans-serif;
}

.multi-select-dropdown {
  position: relative;
  display: inline-block;
  width: 100%;
}

.dropdown-button {
  padding: 8px 16px;
  border: 1px solid #ccc;
  background-color: #f9f9f9;
  cursor: pointer;
  width: 100%;
  background: white;
  width: 100%;
  padding: 14px;
  border: 1px solid #ccc;
  border-radius: 8px;
  box-sizing: border-box;
  text-align: left;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
  border: 1px solid #ccc;
  width: 100%;
}

.multi-select-dropdown:hover .dropdown-content {
  display: block;
}

.dropdown-item {
  display: block;
  padding: 8px 16px;
  cursor: pointer;
}

.dropdown-item input {
  margin-right: 8px;
}

.small-label {
  label {
    font-size: 13px;
    font-weight: bold;
  }
}

