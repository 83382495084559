@use './main';

.icon {
    width: 20px;
    height: 20px;
    cursor: pointer;
}

.icon-small {
    width: 20px;
    cursor: pointer;
}   

.icon-medium {
    width: 30px;
    cursor: pointer;
}

.icon-large {
    width: 40px;
    cursor: pointer;
}

.text-ai-icon {
    width: 25px;
    height: 25px;
    color: blue;
    cursor: pointer;
}

.ai-generate-icon {
    width: 25px;
    height: 25px;
    color: rgb(158, 158, 28);
    cursor: pointer;
}