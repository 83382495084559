@use '../../sass/main';


.task-simple-form {
    @include main.flex($justify: space-between, $direction: column);
    box-sizing: border-box;
    height: 100%;
  
    .title {
      text-align: center;
    }
  
    .form-fields {
      width: 100%;
      overflow-y: auto;
      overflow-x: hidden;
      height: 80%;
      padding: 10px;
  
      &::-webkit-scrollbar {
        width: 10px;
      }
      
      &::-webkit-scrollbar-track {
        background: #f1f1f1;
      }
      
      &::-webkit-scrollbar-thumb {
        background: main.$gray-300;
        border-radius: 10px;
      }
      
      &::-webkit-scrollbar-thumb:hover {
        background: #555;
      }
    }
  
    .submit-button {
      @include main.flex($align: center, $justify: center);
    }
  
  }
  
  