@use '../../sass/main';


.confirmation-modal {
    @include main.flex(space-between, center, column);
    height: 100%;

    .header {
        @include main.flex(center);
        width: 100%;
        margin-bottom: 20px;
    }

    .content {
        @include main.flex(center, center, column);
        width: 100%;
   
        h2 {
            color: main.$gray-900;
            font-size: 25px;
            font-style: normal;
            font-weight: 500;
            line-height: 28px;
            margin-bottom: 5px;
        }     
        p {
            color: main.$gray-500;
        }   
    }

    .footer {
        @include main.flex(space-between, flex);
        width: 100%;
        margin-top: 20px;

        button {
            width: 48%;
        }
    }   
}